<template>
  <span class="hidden md:block w-screen bg-info h-2 noneBlurTilte"></span>
  <nav class="md:hidden absolute w-full" :class="$route.name !== 'Home' ? 'bg-info' : null" style="z-index: 9999">
    <div class="container mx-auto px-5 flex items-center justify-between py-5">
      <i
        @click="toggle = !toggle"
        class="bx bx-menu-alt-right text-white text-4xl cursor-pointer"
      ></i>
      <img @click="$router.push('/')" class="cursor-pointer" src="../assets/whitelogo.svg" alt="" />
    </div>
    <div
      class="w-full absolute z-50 h-screen bg-white transition-all duration-500 inset-0"
      :style="toggle ? 'top: 0px' : 'top: -130vh'"
    >
      <div class="flex items-center justify-between p-5">
        <i @click="toggle = !toggle" class="bx bx-x text-black text-5xl"></i>
        <img @click="$router.push('/')" class="cursor-pointer" src="../assets/logo.svg" alt="" />
      </div>
      <ul class="text-center w-full">
        <li
          v-for="(item, key) in li"
          :key="key"
          class="text-black font-medium py-2 w-1/2 mx-auto my-5 text-lg leading-relaxed"
          :class="
            item.link.includes($route.name)
              ? 'text-primary font-semibold border-b border-primary'
              : null
          "
        >
          <router-link :to="{ name: item.link[0] }" @click="toggle = !toggle">{{
            item.name
          }}</router-link>
        </li>
      </ul>
    </div>
  </nav>
  <nav
    class="hidden md:flex container mx-auto px-5 md:px-8 items-center justify-between py-5" style="z-index: 9999"
  >
    <img @click="$router.push('/')" class="cursor-pointer" src="../assets/ZainOfficialLogoPNG.png" alt="زين" height="150" width="200" />

    <ul class="hidden md:flex items-center justify-around w-3/4 xl:w-1/2">
      <li
        v-for="(item, key) in li"
        :key="key"
        class="text-black font-medium py-2  leading-relaxed"
        :class="
          item.link.includes($route.name)
            ? 'text-primary font-semibold border-b border-primary'
            : null
        "
      >
        <router-link :to="{ name: item.link[0] }">{{ item.name }}</router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "navBar",
  data() {
    return {
      search: "",
      toggle: false,
      li: [
        { name: "الرئيسية", link: ["Home"] },
        { name: "عن الشركة", link: ["About", 'Tasks', 'Principles', 'Structure'] },
        { name: "خدماتنا", link: ["Services"] },
        { name: "سياسات الشركة", link: ["Terms"] },
        { name: "تواصل معنا", link: ["Contact"] },
      ],
    };
  },
};
</script>
